const firebaseConfig = {
  apiKey: "AIzaSyD_61ZRgRrgFlxmkEd1VGI7X9Cf0Mmw5Zk",
  authDomain: "chadgpt-d3458.firebaseapp.com",
  projectId: "chadgpt-d3458",
  storageBucket: "chadgpt-d3458.appspot.com",
  messagingSenderId: "558151040206",
  appId: "1:558151040206:web:ee3ded3c149a737b3c649f",
  measurementId: "G-JJZVGEQGWQ",
};

export default firebaseConfig;
